'use client';

import { Link } from '@/i18n/routing';
import { Button } from '@/ui';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';

export default function HeroSection() {
  const t = useTranslations();

  return (
    <section className="bg-cover bg-bottom pt-8">
      <div className="container flex flex-col lg:flex-row items-center gap-10 py-4 xl:py-20 2xl:py-28">
        <div className="space-y-6 md:space-y-10 basis-1/2">
          <h1 className="text-[36px] xl:text-6xl 2xl:text-8xl font-bold max-w-[500px] xl:max-w-[648px] 2xl:!leading-[90px] max-lg:mx-auto max-lg:text-center">
            <span className="text-primary">
              {t('tarsheed-home-page.title-part-1')}
            </span>{' '}
            {t('tarsheed-home-page.title-part-2')}{' '}
            <span className="text-primary">
              {t('tarsheed-home-page.title-part-3')}
            </span>
          </h1>
          <p className="text-gray-800 max-lg:text-center">
            {t('tarsheed-home-page.description')}
          </p>
          <div className="flex justify-center lg:justify-start">
            <Link href={'/courses'}>
              <Button className="bg-primary">{t('button.start-now')}</Button>
            </Link>
          </div>
        </div>
        <Image
          src="/Images/Tarsheed/Tarsheed-Home-NewHero.jpg"
          width={700}
          height={700}
          alt="Picture of the author"
          className="max-lg:mx-auto"
        />
      </div>
    </section>
  );
}
