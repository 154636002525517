import { cn } from '@/utils';
import React from 'react';

type SectionTitleProps = {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  align?: 'center' | 'left' | 'right';
};
export default function SectionTitle({
  children,
  className,
  size = 'lg',
  align = 'left',
}: SectionTitleProps) {
  return (
    <h1
      className={cn(
        'text-gray-700 font-bold',
        size === 'sm' && 'text-xl md:text-2xl mb-2',
        size === 'md' && 'text-2xl md:text-3xl mb-4',
        size === 'lg' && 'text-3xl md:text-5xl mb-10',
        align === 'center' && 'text-center',
        align === 'left' && 'text-left',
        align === 'right' && 'text-right',
        className,
      )}
    >
      {children}
    </h1>
  );
}
