function MyCourseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      color="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M20.5 16.929V10c0-3.771 0-5.657-1.172-6.828S16.271 2 12.5 2h-1C7.729 2 5.843 2 4.672 3.172S3.5 6.229 3.5 10v9.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M20.5 17H6a2.5 2.5 0 0 0 0 5h14.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M20.5 17a2.5 2.5 0 0 0 0 5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.96 9.838c-.125.538-.722.917-1.915 1.676-1.154.734-1.73 1.101-2.195.954a1.14 1.14 0 0 1-.508-.336C10 11.746 10 10.997 10 9.5s0-2.246.341-2.631c.142-.16.317-.276.509-.337.465-.147 1.041.22 2.195.954 1.193.759 1.79 1.138 1.916 1.676.052.221.052.455 0 .676Z"
      ></path>
    </svg>
  );
}

export default MyCourseIcon;
