export const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT;
export const kamilDigitalApiEndPoint = process.env.KAMIL_DIGITAL_API_ENDPOINT;
export const assetsUrl = process.env.NEXT_PUBLIC_ASSETS_URL;
export const jwtSecret = process.env.JWT_SECRET;
export const resendApiKey = process.env.RESEND_API_KEY;
export const senderEmailAddress = process.env.SENDER_EMAIL_ADDRESS;
export const adminEmailAddress = process.env.ADMIN_EMAIL_ADDRESS;
export const appName = process.env.APP_NAME;
export const baseUrl = process.env.BASE_URL;
export const systemUserId = 0;
export const authTokenLocalStorageKey =
  process.env.NEXT_PUBLIC_AUTH_LOCAL_STORAGE_TOKEN_KEY;

import { useTranslations } from 'next-intl';

export const liveClassDurationInHours = 4;

export const useHeaderNavLinks = () => {
  const t = useTranslations('navigation');
  return [
    {
      title: t('browse'),
      slug: '/courses',
    },
    {
      title: t('myClasses'),
      slug: '/my-classes',
    },
  ];
};
