import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/Images/hero.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/course-card/course-card-price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home-templates/tarsheed/components/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/demo-sections/feature-section-demo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/demo-sections/hero-section-demo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/components/sections/clients-review.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/placeholders/error-placeholder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/progress.tsx");
