'use client';

import { logger } from '@/logger';

if (typeof window !== 'undefined') {
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    // Format error to match the structure expected by errorifyMessages
    const formattedError = {
      type: error?.name || 'WindowError',
      stack: error?.stack || `${url}:${lineNo}:${columnNo}`,
      message: error?.message || msg,
    };

    logger.error(formattedError);
    return false;
  };

  window.addEventListener('unhandledrejection', function (event) {
    const error = event.reason;
    // Format unhandled rejection to match the structure
    const formattedError = {
      type: error?.name || 'UnhandledPromiseRejection',
      stack: error?.stack,
      message: error?.message || String(event.reason),
    };

    logger.error(formattedError);
  });
}

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
