'use client';

import {
  ComputerPhoneSyncIcon,
  DollarCircleIcon,
  SquareLock02Icon,
  UserSwitchIcon,
} from 'hugeicons-react';
import SectionTitle from '../../../section-title/section-title';

export default function FeatureSectionDemo() {
  return (
    <section className="py-5 md:py-6 lg:py-15">
      <SectionTitle className="w-full">
        The trusted way we work with
      </SectionTitle>

      <div className="grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div className="text-center bg-white py-[56px] px-[64px]  shadow-sm rounded-md">
          <div className="bg-warning-50 rounded-full flex items-center justify-center w-fit mx-auto p-[17px] mb-[22px]">
            <ComputerPhoneSyncIcon className="text-warning-500" />
          </div>
          <h3 className="text-lg text-gray-900 font-semibold mb-2">
            Newest tech
          </h3>
          <p className="text-sm text-gray-600">
            Leveraging cutting-edge tools, to enhance learning experiences.
          </p>
        </div>
        <div className="text-center bg-white py-[56px] px-[64px]  shadow-sm rounded-md">
          <div className="bg-[#E2598B] bg-opacity-10 rounded-full flex items-center justify-center w-fit mx-auto p-[17px] mb-[22px]">
            <SquareLock02Icon className="text-[#E2598B]" />
          </div>
          <h3 className="font-semibold mb-2">Security First</h3>
          <p className="text-sm text-gray-600">
            Safeguarding data with robust protection, for secure and trusted
            access.
          </p>
        </div>
        <div className="text-center bg-white py-[56px] px-[64px]  shadow-sm rounded-md">
          <div className="bg-[#4D12EE] bg-opacity-10 rounded-full flex items-center justify-center w-fit mx-auto p-[17px] mb-[22px]">
            <UserSwitchIcon className="text-blue-500" />
          </div>
          <h3 className="font-semibold mb-2">User Friendly</h3>
          <p className="text-sm text-gray-600">
            Intuitive design for seamless learning, so you can focus on what
            matters.
          </p>
        </div>
        <div className="text-center bg-white py-[56px] px-[64px]  shadow-sm rounded-md">
          <div className="bg-[#277E4B] bg-opacity-10 rounded-full flex items-center justify-center w-fit mx-auto p-[17px] mb-[22px]">
            <DollarCircleIcon className="text-success-700" />
          </div>
          <h3 className="font-semibold mb-2">Free Courses</h3>
          <p className="text-sm text-gray-600">
            Unlock knowledge at no cost, empowering everyone to learn.
          </p>
        </div>
      </div>
    </section>
  );
}
