import { TarsheedUtils } from '@/utils/tarsheed-utils';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';

export type LogoProps = Pick<ImageProps, 'height' | 'width'> & {
  variant: 'color' | 'white' | 'icon';
  className?: string;
  alt?: string;
  customerCode?: string;
  src?: string;
};

const logoVariant: Record<LogoProps['variant'], string> = {
  color: '/Images/logo/logo.png',
  white: '/Images/logo/logo-white.png',
  icon: '/Images/logo/logo-icon.png',
};

const logoVariantTarsheed: Record<LogoProps['variant'], string> = {
  color: '/Images/logo/tarsheed-logo.svg',
  white: '/Images/logo/tarsheed-logo-white.svg',
  icon: '/Images/logo/tarsheed-logo.png',
};

export default function Logo({
  variant,
  className,
  width = 125,
  height = 40,
  alt = 'Academy suite Logo',
  src,
  customerCode,
}: LogoProps) {
  return (
    <Link className={className} href="/">
      <Image
        src={
          src
            ? src
            : customerCode && TarsheedUtils.isTarsheedAcademy(customerCode)
              ? logoVariantTarsheed[variant]
              : logoVariant[variant]
        }
        alt={alt}
        width={width}
        height={height}
      />
    </Link>
  );
}
