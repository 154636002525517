'use client';

import { authTokenLocalStorageKey } from '@/config';

import { CustomerViewModel } from '@hidayahlab/academy-suite-corelib';
import { User } from 'lucia';
import { createContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type IAuthContext = {
  isAuthenticated?: boolean;
  user?: User & Pick<CustomerViewModel, 'code'>;
  token?: string;
  setAuth: (
    user: User & Pick<CustomerViewModel, 'code'>,
    token: string,
  ) => void;
  removeAuth: () => void;
  setUser: (user: User & Pick<CustomerViewModel, 'code'>) => void;
};

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export default function AuthProvider({ children }: Props) {
  const [userState, setUserState] = useState<
    User & Pick<CustomerViewModel, 'code'>
  >();
  const [token, setToken] = useLocalStorage<string>(
    authTokenLocalStorageKey ?? '',
    '',
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  useEffect(() => {
    if (!token) return;
  }, [token, userState]);

  const setAuth = (
    user: User & Pick<CustomerViewModel, 'code'>,
    token: string,
  ) => {
    setUserState(user);
    setToken(token);
    setIsAuthenticated(!!token);
  };

  const removeAuth = () => {
    setUserState(undefined);
    setToken('');
    setIsAuthenticated(false);
  };

  const setUser = (user: User & Pick<CustomerViewModel, 'code'>) => {
    setUserState(user);
  };

  return (
    <AuthContext.Provider
      value={{
        user: userState,
        token,
        isAuthenticated,
        setAuth,
        removeAuth,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
