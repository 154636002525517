import pino from 'pino';

const sendToLoki = async (logString: string) => {
  try {
    const logEntry = {
      streams: [
        {
          stream: {
            app: 'academy-suite-user-app',
            environment: process.env.ENV || 'local',
            service: 'academy-suite-user-app',
          },
          values: [[(Date.now() * 1_000_000).toString(), logString]],
        },
      ],
    };

    const response = await fetch(`${process.env.LOKI_HOST}/loki/api/v1/push`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logEntry),
    });

    if (!response.ok) {
      console.error('Loki API error:', {
        status: response.status,
        statusText: response.statusText,
      });
    }
  } catch (error) {
    console.error('Failed to send log to Loki:', error);
  }
};

export const logger = (defaultConfig = {}): pino.Logger => {
  const stream = {
    write: (msg: string) => {
      process.stdout.write(msg);
      if (process.env.ENABLE_LOG_COLLECTION === 'true') {
        sendToLoki(msg);
      }
    },
  };

  return pino(
    {
      ...defaultConfig,
      formatters: {
        level: label => {
          return { level: label.toUpperCase() };
        },
      },
      timestamp: pino.stdTimeFunctions.isoTime,
    },
    stream,
  );
};
