'use client';

import NumberFormatter from '@/utils/bn';
import { useLocale } from 'next-intl';
import React from 'react';

type CourseCardPriceProps = {
  price: number;
  discount?: number;
  discountPrice?: number;
  isFree?: boolean;
};
export default function CourseCardPrice({
  price,
  discountPrice,
  isFree,
}: CourseCardPriceProps) {
  const local = useLocale();

  return (
    <div className="flex items-center gap-3 px-4 pb-4">
      <p className="text-primary-500 text-2xl font-bold ">
        {isFree && price === 0
          ? local === 'bn'
            ? 'ফ্রী'
            : 'Free'
          : `${local === 'bn' ? `৳ ${NumberFormatter(discountPrice ?? 0)} ` : `$${discountPrice}`}`}
      </p>
      {!isFree && price >= 0 && (
        <p className="line-through lg:mt-2 text-body font-semibold">
          {local === 'bn' ? `৳ ${NumberFormatter(price)}` : `$${price}`}
        </p>
      )}
    </div>
  );
}
