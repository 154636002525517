'use client';

import { useHeaderNavLinks } from '@/config';
import { Link, usePathname } from '@/i18n/routing';
import { Drawer, DrawerContent, DrawerTrigger } from '@/ui/drawer';
import { Button, cn, Search } from '@tajdid/tajdid-ui';
import { XCloseIcon } from '@tajdid/tajdid-ui/icons';
import { Menu02Icon } from 'hugeicons-react';
import { User } from 'lucia';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { Logo } from '../logo';
import {
  CustomerViewModel,
  FilePathUtils,
} from '@hidayahlab/academy-suite-corelib';
import BetaBadge from './beta-badge';
import { fallbackImages } from '@/utils/fallback-images';
import { TarsheedUtils } from '@/utils/tarsheed-utils';
import HomeIcon from '../icon/home-icon';
import CourseIcon from '../icon/course-icon';
import MyCourseIcon from '../icon/mycourse-icon';
import ProfileIcon from '../icon/profile-icon';

const applicablePaths = ['/', '/courses', '/my-classes', '/profile'];

type NavbarDetailsProps = {
  user?: User | null;
  handleLogout: () => void;
  customer?: CustomerViewModel;
};

export const hideNavbar = [''];

export const isResourcePage = (pathName: string) => {
  const pattern = /^\/my-curriculum\/[^\/]+\/resources\/.*/;
  return pattern.test(pathName);
};

export default function NavbarDetails({ user, customer }: NavbarDetailsProps) {
  const t = useTranslations();
  const pathName = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const headerNavLinks = useHeaderNavLinks();

  const [isSticky, setIsSticky] = useState(false);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: [1] },
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, []);

  return (
    <>
      <div ref={sentinelRef} id="sentinel"></div>
      {!hideNavbar.includes(pathName) && (
        <nav
          className={`header md:fixed top-0 z-50 shadow-xs w-full ${isSticky ? 'bg-white' : ' bg-transparent transition'}`}
        >
          <div className="container gap-4 lg:py-7">
            <div className="items-center justify-between hidden gap-5 lg:flex ">
              <div className="relative">
                {!TarsheedUtils.isTarsheedAcademy(customer?.code ?? '') && (
                  <BetaBadge />
                )}
                <Logo
                  src={
                    (customer?.logoColor &&
                      `${FilePathUtils.customerLogoPath(customer.id, 'color')}/${customer.logoColor}`) ??
                    ''
                  }
                  variant="color"
                  width={170}
                  height={25}
                />
              </div>
              <div className="w-[280px] hidden">
                <Search
                  onClick={() => {}}
                  placeholder="Search for classes, skills, teachers"
                />
              </div>
              <div className="flex items-center gap-2 ml-auto mr-10">
                {headerNavLinks.map(item => (
                  <Link
                    className={`text-base hover:text-black p-2 ${item.slug === pathName ? 'text-primary font-semibold' : 'text-gray-500'}`}
                    key={item.slug}
                    href={
                      item.slug === '/my-classes'
                        ? user
                          ? '/my-classes'
                          : `/login?redirect=/my-classes`
                        : item.slug
                    }
                  >
                    {item.title}
                  </Link>
                ))}
                {!user && (
                  <Link href="/login">
                    <Button>{t('button.signIn')}</Button>
                  </Link>
                )}
              </div>

              {user && (
                <Link className="flex items-center gap-2" href="/profile">
                  <Image
                    className="w-8 h-8 rounded-full"
                    height={32}
                    width={32}
                    alt="profile"
                    src={
                      user.picture
                        ? `${FilePathUtils.userProfilePath(user.id)}/${user.picture}`
                        : fallbackImages.user
                    }
                  />
                  <p className="mt-1 text-sm font-semibold text-gray-700">
                    {user.name ?? 'Anonymous'}
                  </p>
                </Link>
              )}
            </div>

            <div
              className={cn(
                'flex items-center justify-between py-6 lg:hidden',
                isResourcePage(pathName) && 'hidden',
              )}
            >
              <div className="flex gap-4">
                <Drawer direction="left" open={isOpen} onOpenChange={setIsOpen}>
                  <DrawerTrigger>
                    <Menu02Icon />
                  </DrawerTrigger>
                  <DrawerContent>
                    <div className="mobile-menu relative">
                      <div className="p-4 cursor-pointer close-icon absolute top-3 right-3">
                        <XCloseIcon onClick={() => setIsOpen(false)} />
                      </div>
                      <div
                        className="p-4  mobile-menu-logo w-fit"
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="relative scale-75 -ml-6">
                          {!TarsheedUtils.isTarsheedAcademy(
                            customer?.code ?? '',
                          ) && <BetaBadge />}
                          <Logo
                            src={
                              (customer?.logoColor &&
                                `${FilePathUtils.customerLogoPath(customer.id, 'color')}/${customer.logoColor}`) ??
                              ''
                            }
                            variant="color"
                            width={175}
                            height={34}
                          />
                        </div>
                      </div>

                      <div
                        className={cn(
                          'p-3',
                          applicablePaths.includes(pathName),
                        )}
                      >
                        <Link
                          href="/courses"
                          className={cn(
                            'flex items-center gap-2 mb-3 text-gray-600 border-b-1 pb-3',
                            pathName === '/browse' && 'text-primary-600',
                          )}
                          onClick={() => setIsOpen(false)}
                        >
                          <p>{t('navigation.browse')}</p>
                        </Link>

                        <Link
                          href={
                            user ? '/my-classes' : `/login?redirect=/my-classes`
                          }
                          className={cn(
                            'flex items-center gap-2 mb-3 text-gray-600 border-b-1 pb-3',
                            pathName === '/my-classes' && 'text-primary-600',
                          )}
                          onClick={() => setIsOpen(false)}
                        >
                          <p>{t('navigation.myClasses')}</p>
                        </Link>
                      </div>
                    </div>
                  </DrawerContent>
                </Drawer>
                <div className="relative max-sm:scale-75 max-sm:-ml-5">
                  {!TarsheedUtils.isTarsheedAcademy(customer?.code ?? '') && (
                    <BetaBadge />
                  )}
                  <Logo
                    src={
                      (customer?.logoColor &&
                        `${FilePathUtils.customerLogoPath(customer.id, 'color')}/${customer.logoColor}`) ??
                      ''
                    }
                    variant="color"
                    width={175}
                    height={34}
                  />
                </div>
              </div>
              <div className="flex col-span-2 gap-4 justify-self-end">
                {!user && (
                  <Link href="/login">
                    <Button>{t('button.signIn')}</Button>
                  </Link>
                )}
                {user && (
                  <Link className="flex items-center gap-2" href="/profile">
                    <Image
                      className="w-8 h-8 rounded-full"
                      height={32}
                      width={32}
                      alt="profile"
                      src={
                        user.picture
                          ? `${FilePathUtils.userProfilePath(user.id)}/${user.picture}`
                          : fallbackImages.user
                      }
                    />
                    <p className="mt-1 text-sm font-semibold text-gray-700 hidden">
                      {user.name}
                    </p>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
      <div className="mt-24"></div>
      <section className="fixed z-50 flex justify-around bottom-0 left-0 w-full bg-white py-3 md:hidden">
        <Link
          href="/"
          className={`flex flex-col items-center ${'/' === pathName ? 'text-primary' : 'text-gray-500'}`}
        >
          <HomeIcon />
          <p>Home</p>
        </Link>

        <Link
          href="/courses"
          className={`flex flex-col items-center ${'/courses' === pathName ? 'text-primary' : 'text-gray-500'}`}
        >
          <CourseIcon />
          <p>Course</p>
        </Link>

        <Link
          href="/my-classes"
          className={`flex flex-col items-center ${'/my-classes' === pathName ? 'text-primary' : 'text-gray-500'}`}
        >
          <MyCourseIcon />
          <p>My Course</p>
        </Link>

        <Link
          href="/profile"
          className={`flex flex-col items-center ${'/profile' === pathName ? 'text-primary' : 'text-gray-500'}`}
        >
          <ProfileIcon />
          <p>Profile</p>
        </Link>
      </section>
    </>
  );
}
