'use client';

import { logger } from '@/logger';
import { Button, cn } from '@tajdid/tajdid-ui';
import { AlertCircleIcon } from 'hugeicons-react';

type ErrorPlaceholderProps = {
  title?: string;
  description?: string;
  retry?: () => void;
  align?: 'center' | 'left';
};

export default function ErrorPlaceholder({
  title = 'Something went wrong',
  description = 'There was an error loading this content. Please try again later.',
  retry,
  align = 'left',
}: ErrorPlaceholderProps) {
  logger.error(`ErrorPlaceholder: ${title} ${description}`);

  return (
    <div
      className={cn(
        'flex flex-col max-w-md mx-auto my-8 p-4 bg-error-50 border border-error-200 rounded-lg shadow-sm',
        align === 'center' && 'items-center justify-center',
      )}
    >
      <div className="flex items-center">
        <AlertCircleIcon className="h-5 w-5 text-error-500 mr-2" />
        <h3 className="text-lg font-semibold text-error-800">{title}</h3>
      </div>
      <p
        className={cn(
          'mt-2 text-sm text-error-700',
          align === 'center' && 'text-center',
        )}
      >
        {description}
      </p>
      {retry && (
        <Button
          className="mt-4 w-full px-4 py-2 text-sm font-medium text-error-700 bg-error-100 border border-error-300 rounded-md hover:bg-error-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-error-500 transition-colors"
          onClick={retry}
        >
          Try again
        </Button>
      )}
    </div>
  );
}
