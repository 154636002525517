import { FilePathUtils } from '@hidayahlab/academy-suite-corelib';

const assetUrl = FilePathUtils.images();

export const fallbackImages = {
  course: `${assetUrl}/course-fallback-image.jpg`,
  courseCertificate: `${assetUrl}/course-certificate.jpg`,
  textureBackground: `${assetUrl}/texture-background.png`,
  medal: `${assetUrl}/medal.svg`,
  medal2: `${assetUrl}/support-instructor-icon.svg`,
  ellipse: `${assetUrl}/ellipse.svg`,
  user: `${assetUrl}/user-fallback.svg`,
  courseDetailsBackground: `${assetUrl}/course-details-bg.jpg`,
};
