'use client';

import Button from '@/ui/button';
import { cn } from '@/utils';
import { ButtonProps } from '@tajdid/tajdid-ui';
import { useFormStatus } from 'react-dom';

export default function SubmitButton(props: ButtonProps) {
  const { pending } = useFormStatus();
  const { children, disabled, className } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      className={cn('px-8 py-3', className)}
      fullWidth
      isLoading={pending}
      disabled={disabled || pending}
      {...props}
    >
      {children}
    </Button>
  );
}
