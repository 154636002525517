'use client';

import { completeActivity } from '@/app/[domain]/[locale]/actions/activity-action';
import { getNextAndPreviousItem } from '@/app/[domain]/[locale]/my-classes/utils/getNextAndPreviousItem';
import { CourseCurriculum } from '@hidayahlab/academy-suite-corelib';
import { useRouter } from '@/i18n/routing';

type Props = {
  url: string;
};

export default function GotipathPlayer({ url }: Props) {
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
      }}
    >
      <iframe
        src={`https://player.gotipath.com/stream/89898172-d32b-49f8-a94a-bdf0c6ca1bd9/default/${url}?autoplay=true&preload=true&loop=true&muted=true`}
        loading="lazy"
        style={{
          border: 'none',
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
        }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen
      ></iframe>
    </div>
  );
}
